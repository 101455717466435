import { Header } from "./components/Header";
import { Profile } from "./components/Profile";
import { Footer } from "./components/Footer";

import './index.css';
import "./css/header.css";
import "./css/profile.css";
import "./css/footer.css";

function App() {
  return (
    <>
    <Header/>
    <Profile/>
    <Footer/>
  </>
  );
}

export default App;
